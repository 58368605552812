import React, {cloneElement, ReactElement, useState} from "react";
import {Container, Grid} from "@mui/material";
import SEO from "../../components/seo/seo";
import Text from "../../components/data-display/text";
import InlineLink from "../../components/data-display/inline-link";
import Typography from "@mui/material/Typography";

export default function QuickTaskTerms() {
  const styles = {
    subHeading: {
      marginBottom: "12px",
    },
  };

  return (
    <div>
      {" "}
      <div>
        {" "}
        <div style={{padding: "10vh 16px"}}>
          <Typography
            variant="h5"
            style={{
              ...(styles.subHeading as React.CSSProperties),
            }}
          >
            QuickTask Effective: 15th January 2024{" "}
          </Typography>
          <div style={{height: "24px"}} />
          <Typography variant="body1">
            The QuickTask Terms of Service <strong>(updated January 2024) </strong>delineate
            QuickTask and your commitments and responsibilities concerning the QuickTask Website,
            App, and Services.
            <br />
            <br />
            Kindly review these Terms of Service attentively. By establishing an account on our
            platforms, you acknowledge that:
            <div style={{marginLeft: "10px", marginTop: "15px"}}>
              <Typography variant="body1">
                {" "}
                1. You have perused, comprehended, and concurred to be bound by these terms of use,
                inclusive of additional policies referenced herein and/or accessible via links.
              </Typography>
              <Typography variant="body1">
                {" "}
                2. You are either of legal age to form a binding contract with QuickTask, or you
                have reached the age of majority in your state of residence and have provided
                consent for any minor dependents to utilise this site.
              </Typography>
              <Typography variant="body1">
                {" "}
                1. You possess the authority to personally enter into the terms of use or represent
                the company you have designated as the user, binding that company to the terms of
                use.
              </Typography>
            </div>
            <br />
            <br />
            <Typography variant="body1">
              {" "}
              The term <b> "you"</b> encompasses all users of the site, including but not limited to
              browsers, vendors, customers, merchants, and content contributors, whether individuals
              or legal entities. The term <b>"Company"</b> refers to QuickTask. The term{" "}
              <b>"Tasker" </b>
              designates the individual undertaking services to fulfil the duties outlined in a
              task. The term <b>"Task Poster" </b>denotes the individual publishing the task for
              completion.
            </Typography>
            <br />
            <br />
            <Typography variant="body1" style={{fontWeight: "bold"}}>
              IF YOU DISAGREE WITH THE TERMS OF USE, ACCESSING OR USING THIS WEBSITE OR SERVICES IS
              NOT PERMITTED.
            </Typography>
          </Typography>
          {/*//section 1*/}
          <div style={{height: "48px"}} />
          <Text
            variant={"h5"}
            bold
            style={{
              ...(styles.subHeading as React.CSSProperties),
            }}
          >
            1. Scope of QuickTask's Website, App, and Services
          </Text>
          <div className="content">
            <Typography variant="body1">
              QuickTask's platform encompasses a dynamic and expansive scope, offering users a
              versatile online space to streamline task management. The platform's scope is detailed
              as follows:
            </Typography>
            <div style={{marginLeft: "5px"}}>
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  1.1 Central Hub for Task Facilitation
                </Typography>
                <Typography variant="body1">
                  QuickTask serves as a central hub where users can seamlessly post a diverse array
                  of tasks for completion. This strategic role creates an interactive marketplace,
                  connecting individuals seeking assistance with taskers ready to take on various
                  challenges.
                </Typography>
              </div>
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  1.2 Vibrant Marketplace Dynamics
                </Typography>
                <Typography variant="body1">
                  The platform's design fosters a vibrant marketplace where users can effortlessly
                  engage with each other. Whether it's posting tasks or taking on assignments,
                  QuickTask ensures a lively and interactive environment that enhances the overall
                  user experience.
                </Typography>
              </div>
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  1.3 Broad Task Categories
                </Typography>
                <Typography variant="body1">
                  QuickTask accommodates a broad spectrum of task categories, providing users with
                  flexibility in task creation. From household chores to specialised skilled
                  services, the platform encourages users to explore and post tasks that align with
                  their unique needs.
                </Typography>
              </div>
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  1.4 Collaborative Task Execution
                </Typography>
                <Typography variant="body1">
                  Emphasising collaboration, QuickTask promotes an environment conducive to
                  efficient task execution. Users are encouraged to work together seamlessly,
                  ensuring tasks are completed with precision and satisfaction.
                </Typography>
              </div>
              <Typography variant="h6" style={{marginTop: "10px"}}>
                Additional Points:
              </Typography>
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  1.5 User Flexibility
                </Typography>
                <Typography variant="body1">
                  Users enjoy unparalleled flexibility in posting tasks that cater to their
                  individual needs. QuickTask's inclusive platform accommodates a wide range of task
                  categories, allowing users to find solutions for various requirements.
                </Typography>
              </div>
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  1.6 Task Diversity
                </Typography>
                <Typography variant="body1">
                  QuickTask actively encourages diversity in task offerings. The platform recognizes
                  and celebrates the unique requirements of its user base, creating a space where
                  users can explore an extensive range of tasks and services.
                </Typography>
              </div>
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  1.7 Community Engagement
                </Typography>
                <Typography variant="body1">
                  The collaborative environment fostered by QuickTask extends beyond task execution.
                  The platform is designed to encourage engagement within the user community,
                  creating a space where individuals can connect, collaborate, and contribute to
                  each other's success.
                </Typography>
              </div>
            </div>
          </div>
          {/*//section 2*/}
          <div style={{height: "48px"}} />
          <Text
            variant={"h5"}
            bold
            style={{
              ...(styles.subHeading as React.CSSProperties),
            }}
          >
            2. QuickTask's Role and Obligations
          </Text>
          <div className="content">
            <Typography variant="body1">
              QuickTask assumes a crucial role in facilitating transactions and ensuring the
              seamless execution of tasks within its platform. The platform's responsibilities and
              obligations are outlined as follows:
            </Typography>

            <div style={{marginLeft: "5px"}}>
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  2.1 Financial Transaction Facilitation
                </Typography>
                <Typography variant="body1">
                  QuickTask serves as an intermediary in the financial transaction process. Users
                  entrust payments to QuickTask, establishing a secure and efficient exchange system
                  that safeguards the interests of both task posters and task performers.
                </Typography>
              </div>
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  2.2 Secure Fund Transfer
                </Typography>
                <Typography variant="body1">
                  Upon successful completion of a task, QuickTask takes the initiative to transfer
                  funds to the tasker's account. This process is executed promptly and securely,
                  reflecting QuickTask's commitment to ensuring that users receive their due
                  compensation in a timely manner.
                </Typography>
              </div>
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  2.3 Deduction of Nominal Commission
                </Typography>
                  <Typography variant="body1">
                QuickTask operates on a sustainable business model, and as part of this, a nominal commission is deducted from the total task value as follows:
                  <ul>
                    <li>15% for tasks under £500</li>
                    <li>10% for tasks between £500 and £1,000</li>
                    <li>7.5% for tasks over £1,000</li>
                  </ul>
                This commission contributes to the maintenance and improvement of the platform, allowing QuickTask to continue providing valuable services to its user community.
               </Typography>
              </div>
              <Typography variant="h6" style={{marginTop: "10px"}}>
                Additional Points:
              </Typography>

              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  2.4 Reliable Intermediary
                </Typography>
                <Typography variant="body1">
                  QuickTask's role as a reliable intermediary is central to fostering trust among
                  users. The platform actively works to create an environment where users can
                  confidently engage in transactions, knowing that QuickTask acts as a trustworthy
                  facilitator.
                </Typography>
              </div>
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  2.5 Trust and Transparency
                </Typography>
                <Typography variant="body1">
                  Trust and transparency are fundamental principles guiding QuickTask's operations.
                  The platform prioritises these values throughout the financial transaction
                  process, ensuring that users have a clear understanding of each step and can trust
                  in the reliability of the platform.
                </Typography>
              </div>
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  1.6 Task Diversity
                </Typography>
                <Typography variant="body1">
                  QuickTask actively encourages diversity in task offerings. The platform recognizes
                  and celebrates the unique requirements of its user base, creating a space where
                  users can explore an extensive range of tasks and services.
                </Typography>
              </div>
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  2.6 Commitment to User Satisfaction
                </Typography>
                <Typography variant="body1">
                  QuickTask is dedicated to user satisfaction, and this commitment extends to the
                  financial aspect of task completion. The platform prioritises prompt payment
                  processing, recognizing the importance of timely compensation for task performers,
                  thereby enhancing the overall user experience.
                </Typography>
              </div>
            </div>
          </div>
          {/*//section 3*/}
          <div style={{height: "48px"}} />
          <Text
            variant={"h5"}
            bold
            style={{
              ...(styles.subHeading as React.CSSProperties),
            }}
          >
            3. User Obligations
          </Text>
          <div className="content">
            <Typography variant="body1">
              QuickTask places a strong emphasis on user obligations, setting the foundation for a
              positive and collaborative environment. Users play a pivotal role in maintaining the
              integrity of the platform, and adherence to ethical conduct is paramount:
            </Typography>

            <div style={{marginLeft: "5px"}}>
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  3.1 Responsible and Ethical Behaviour
                </Typography>
                <Typography variant="body1">
                  Users are expected to engage in responsible and ethical behaviour while
                  participating in the QuickTask community. This includes treating fellow users with
                  respect, honesty, and fairness, creating a conducive atmosphere for collaboration.
                </Typography>
              </div>
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  3.2 Open Communication
                </Typography>
                <Typography variant="body1">
                  Transparent communication is a cornerstone of the QuickTask experience. Users are
                  encouraged to openly communicate with each other, particularly when collaborating
                  on tasks. This not only fosters trust but also ensures that expectations are
                  clearly communicated and understood.
                </Typography>
              </div>
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  3.3 Clear Task Descriptions
                </Typography>
                <Typography variant="body1">
                  Clear and concise task descriptions are essential for a seamless user experience.
                  Task posters are obligated to provide accurate and detailed descriptions, enabling
                  task performers to assess the requirements and make informed decisions about task
                  acceptance.
                </Typography>
              </div>
              <Typography variant="h6" style={{marginTop: "10px"}}>
                Additional Points:
              </Typography>
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  3.4 Adherence to Community Guidelines
                </Typography>
                <Typography variant="body1">
                  Users must adhere to the established community guidelines outlined by QuickTask.
                  These guidelines serve as a framework for acceptable behaviour, promoting a
                  harmonious and respectful online community.
                </Typography>
              </div>{" "}
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  3.5 Transparent Communication Throughout Tasks
                </Typography>
                <Typography variant="body1">
                  The encouragement for transparent communication extends throughout the entire task
                  process. QuickTask advocates for ongoing and clear communication, ensuring that
                  any developments or changes related to the task are promptly shared between the
                  involved parties.
                </Typography>
              </div>
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  3.6 Promotion of Responsibility
                </Typography>
                <Typography variant="body1">
                  QuickTask actively promotes a sense of responsibility among its users. This
                  includes being accountable for one's actions, ensuring the accuracy of information
                  shared, and upholding the principles of fairness and integrity within the
                  QuickTask community.
                </Typography>
              </div>
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  3.7 Fostering a Respectful and Collaborative Community
                </Typography>
                <Typography variant="body1">
                  The collective efforts of users contribute to fostering a respectful and
                  collaborative community on QuickTask. This environment is characterised by
                  positive interactions, mutual respect, and a shared commitment to the principles
                  outlined in the user obligations.
                </Typography>
              </div>
            </div>
          </div>
          {/*//section 4*/}
          <div style={{height: "48px"}} />
          <Text
            variant={"h5"}
            bold
            style={{
              ...(styles.subHeading as React.CSSProperties),
            }}
          >
            4. Third-Party Services{" "}
          </Text>
          <div className="content">
            <Typography variant="body1">
              QuickTask seamlessly integrates Third-Party Services into its platform, broadening the
              spectrum of offerings available to users. It's crucial to understand the role of these
              services, their independence from QuickTask, and the absence of limitations on
              third-party engagement:
            </Typography>

            <div style={{marginLeft: "5px"}}>
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  4.1 Diverse Third-Party Integrations
                </Typography>
                <Typography variant="body1">
                  QuickTask strategically incorporates various Third-Party Services, including but
                  not limited to providers like Google, Stripe, and hosting platforms. These
                  integrations are designed to enhance the overall user experience by offering a
                  diverse array of functionalities and features.
                </Typography>
              </div>
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  4.2 External Nature of Third-Party Services
                </Typography>
                <Typography variant="body1">
                  It's imperative to recognize that Third-Party Services featured on the QuickTask
                  platform are external entities operating independently. These services contribute
                  to the richness of the platform by providing specialised functionalities, and
                  their offerings are not directly governed by QuickTask.
                </Typography>
              </div>
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  4.3 No Responsibility for Accuracy or Effectiveness
                </Typography>
                <Typography variant="body1">
                  QuickTask explicitly disclaims any responsibility for the accuracy, effectiveness,
                  or outcomes of Third-Party Services. Users are encouraged to exercise due
                  diligence and evaluate the suitability of these services based on their individual
                  needs and preferences.
                </Typography>
              </div>
              <Typography variant="h6" style={{marginTop: "10px"}}>
                Additional Points:
              </Typography>
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  4.4 Expanding Task Options
                </Typography>
                <Typography variant="body1">
                  The incorporation of Third-Party Services significantly expands the range of task
                  options available to users. These services bring diversity and versatility to the
                  platform, catering to a broader spectrum of user requirements and preferences.
                </Typography>
              </div>{" "}
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  4.5 Independence and Suitability Assessment
                </Typography>
                <Typography variant="body1">
                  QuickTask underscores the independence of Third-Party Services and encourages
                  users to assess their suitability based on specific needs. Users have the
                  flexibility to explore and engage with these services as per their preferences,
                  thereby personalising their QuickTask experience.
                </Typography>
              </div>
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  4.6 Integration with Reputable Providers
                </Typography>
                <Typography variant="body1">
                  QuickTask collaborates with reputable third-party providers, such as Google and
                  Stripe, known for their reliability and industry standards. This strategic
                  collaboration aims to ensure a seamless and secure user experience within the
                  QuickTask platform.
                </Typography>
              </div>
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  4.7 No Limitations to Third-Party Engagement{" "}
                </Typography>
                <Typography variant="body1">
                  Users are free to engage with Third-Party Services without limitations imposed by
                  QuickTask. This openness allows users to leverage external services based on their
                  preferences, contributing to a customised and enriched QuickTask experience.
                </Typography>
              </div>
            </div>
          </div>
          {/*//section 5*/}
          <div style={{height: "48px"}} />
          <Text
            variant={"h5"}
            bold
            style={{
              ...(styles.subHeading as React.CSSProperties),
            }}
          >
            5. Verification
          </Text>
          <div className="content">
            <Typography variant="body1">
              Verification on QuickTask is a pivotal aspect of establishing a secure and trustworthy
              environment for all users. The following details QuickTask's approach to user
              verification and introduces additional elements related to user badges:
            </Typography>

            <div style={{marginLeft: "5px"}}>
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  5.1 Identity Verification Services
                </Typography>
                <Typography variant="body1">
                  QuickTask employs robust Identity Verification Services to ascertain the
                  authenticity of new users joining the platform. This comprehensive process
                  involves multifaceted checks that contribute to building a secure foundation for
                  user interactions. By undergoing identity verification, users contribute to the
                  overall safety and reliability of the QuickTask community.
                </Typography>
              </div>
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  5.2 Secure Environment Creation
                </Typography>
                <Typography variant="body1">
                  The employment of Identity Verification Services goes beyond simple
                  authentication; it actively contributes to creating a secure environment within
                  the QuickTask platform. Users can trust that their interactions occur within a
                  protected space, fostering a sense of confidence and reliability.
                </Typography>
              </div>
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  5.3 User Confidence Promotion
                </Typography>
                <Typography variant="body1">
                  Identity Verification serves as a cornerstone in promoting user confidence on the
                  QuickTask platform. When users know that their counterparts have undergone a
                  thorough verification process, it instils trust and bolsters the overall integrity
                  of the community. This confidence is essential for fostering positive and
                  collaborative tasking experiences.
                </Typography>
              </div>
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  5.4 Background Checks for Enhanced Safety
                </Typography>
                <Typography variant="body1">
                  As part of the verification process, background checks are conducted to add an
                  extra layer of safety to the QuickTask community. These checks contribute to the
                  creation of a safer environment, providing users with an additional assurance of
                  security in their interactions with other community members.
                </Typography>
              </div>{" "}
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  5.5 Additional Badges for Verified Users
                </Typography>
                <Typography variant="body1">
                  QuickTask recognizes and appreciates users who go the extra mile to enhance their
                  credibility. Users with additional verifications, such as Gas Safe, NICEIC, or DBS
                  checks, will be awarded special badges displayed on their profiles. These badges
                  serve as visible indicators of users' commitment to maintaining high standards and
                  can be instrumental in attracting potential taskmasters or taskers.
                </Typography>
              </div>{" "}
              <Typography variant="h6" style={{marginTop: "10px"}}>
                Additional Points:
              </Typography>
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  5.6 Trustworthiness Enhancement
                </Typography>
                <Typography variant="body1">
                  The overarching goal of the verification process is to enhance the trustworthiness
                  of the QuickTask platform. By incorporating additional badges for users with
                  specific verifications, QuickTask aims to highlight and reward the commitment to
                  excellence and safety demonstrated by these users.
                </Typography>
              </div>
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  5.7 Community Protection{" "}
                </Typography>
                <Typography variant="body1">
                  The implementation of identity verification and background checks significantly
                  contributes to the protection of the QuickTask community. These measures are
                  designed to mitigate potential risks and create an environment where users can
                  engage with confidence, knowing that their safety is a top priority.
                </Typography>
              </div>
            </div>
          </div>
          {/*//section 6*/}
          <div style={{height: "48px"}} />
          <Text
            variant={"h5"}
            bold
            style={{
              ...(styles.subHeading as React.CSSProperties),
            }}
          >
            6. Insurance{" "}
          </Text>
          <div className="content">
            <Typography variant="body1">
              Given the nature of task-based interactions on the QuickTask platform, it is
              imperative for users to understand the insurance-related aspects. The following
              elucidates QuickTask's stance on insurance and provides additional guidance:
            </Typography>

            <div style={{marginLeft: "5px"}}>
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  6.1 Absence of QuickTask-provided Insurance
                </Typography>
                <Typography variant="body1">
                  QuickTask explicitly states that it does not furnish insurance coverage to users
                  utilising the QuickTask Website, App, or Services. This means that users, both
                  taskmasters and taskers, need to be cognizant of the absence of any inherent
                  insurance protection provided by QuickTask.
                </Typography>
              </div>
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  6.2 User Responsibility for Inquiries
                </Typography>
                <Typography variant="body1">
                  Users bear the responsibility of conducting their inquiries to determine whether
                  additional insurance is necessary for their specific circumstances. QuickTask
                  places the onus on users to make informed decisions regarding the need for
                  supplemental insurance coverage based on their tasks and preferences.
                </Typography>
              </div>{" "}
              <Typography variant="h6" style={{marginTop: "10px"}}>
                Additional Points:
              </Typography>
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  6.3 Tasker's Insurance Accountability
                </Typography>
                <Typography variant="body1">
                  Identity Verification serves as a cornerstone in promoting user confidence on the
                  QuickTask platform. When users know that their counterparts have undergone a
                  thorough verification process, it instils trust and bolsters the overall integrity
                  of the community. This confidence is essential for fostering positive and
                  collaborative tasking experiences.
                </Typography>
              </div>
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  6.4 Encouragement for User Exploration
                </Typography>
                <Typography variant="body1">
                  QuickTask encourages users to explore and obtain insurance coverage that aligns
                  with the nature and scope of their specific tasks. This proactive approach
                  empowers users to safeguard their interests and mitigate risks associated with
                  task-related activities.
                </Typography>
              </div>{" "}
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  6.5 Prioritising Up-to-date Insurance
                </Typography>
                <Typography variant="body1">
                  Taskers are specifically urged to prioritise the maintenance of up-to-date
                  insurance coverage. This emphasis is crucial in ensuring that taskers have
                  adequate protection against potential liabilities that may arise during the
                  fulfilment of tasks. Proactive insurance management contributes to a secure and
                  responsible user community.
                </Typography>
              </div>{" "}
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  6.6 Informed Decision-Making
                </Typography>
                <Typography variant="body1">
                  By disseminating information on the absence of QuickTask-provided insurance and
                  emphasising user responsibilities, QuickTask aims to facilitate informed
                  decision-making. Users are encouraged to assess their insurance needs, explore
                  suitable coverage options, and take necessary precautions for a secure tasking
                  experience.
                </Typography>
              </div>
            </div>
          </div>
          {/*//section 7*/}
          <div style={{height: "48px"}} />
          <Text
            variant={"h5"}
            bold
            style={{
              ...(styles.subHeading as React.CSSProperties),
            }}
          >
            7. Limitation of Liability{" "}
          </Text>
          <div className="content">
            <Typography variant="body1">
              In the interest of maintaining transparency and clarity, QuickTask emphasises the
              limitations of liability associated with the use of its platform. Users should
              carefully consider the following provisions:
            </Typography>

            <div style={{marginLeft: "5px"}}>
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  7.1 Broad Scope of Exclusion
                </Typography>
                <Typography variant="body1">
                  You understand and acknowledge that QuickTask, along with its officers, directors,
                  employees, affiliates, agents, interns, suppliers, or licensors, shall not be held
                  liable for any injury, loss, claim, or indirect, incidental, special,
                  consequential, or exemplary damages. This broad exclusion encompasses a range of
                  potential harms arising from or related to your use of the QuickTask website,
                  services, or products.
                </Typography>
              </div>
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  7.2 Varied Nature of Damages
                </Typography>
                <Typography variant="body1">
                  The specified damages include but are not limited to, those arising out of
                  contract breaches, tortious acts, negligence, strict liability, or any other legal
                  grounds. Users should be aware that the exclusion applies regardless of the legal
                  basis for the damages incurred.
                </Typography>
              </div>{" "}
              <Typography variant="h6" style={{marginTop: "10px"}}>
                Additional Points:
              </Typography>
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  7.3 Agreed-Upon Allocation of Risk
                </Typography>
                <Typography variant="body1">
                  The parties involved in the QuickTask platform acknowledge that the limitation of
                  liability outlined in this section is a result of a mutual agreement on the
                  allocation of risk. This recognition underscores the importance of understanding
                  and accepting the inherent limitations associated with using the platform.
                </Typography>
              </div>
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  7.4 Maximum Limitation Permitted by Law
                </Typography>
                <Typography variant="body1">
                  QuickTask's liability is expressly limited to the maximum extent permitted by law.
                  This restriction holds particularly true in jurisdictions where laws do not allow
                  the exclusion or limitation of liability for certain types of damages. Users
                  should be mindful of these legal constraints when engaging with the QuickTask
                  platform
                </Typography>
              </div>{" "}
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  7.5 Explanatory Acknowledgment
                </Typography>
                <Typography variant="body1">
                  It is crucial for users to recognize that the limitations outlined in this section
                  are integral to the Terms and Conditions Agreement. The parties involved
                  acknowledge and accept these limitations as a fundamental aspect of their
                  engagement with QuickTask.
                </Typography>
              </div>{" "}
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  7.6 Legal Context Consideration
                </Typography>
                <Typography variant="body1">
                  Users are urged to consider the legal context and implications of the limitation
                  of liability, especially in regions where specific laws may impact the
                  enforceability of such provisions. QuickTask strives to adhere to legal standards
                  while providing a transparent framework for user interactions.
                </Typography>
              </div>
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  7.7 Informed Decision-Making
                </Typography>
                <Typography variant="body1">
                  By acknowledging these limitations, users are empowered to make informed decisions
                  about their participation in the QuickTask platform. Understanding the extent of
                  liability limitations ensures a clear understanding of the associated risks and
                  responsibilities.
                </Typography>
              </div>
            </div>
          </div>
          {/*//section 8*/}
          <div style={{height: "48px"}} />
          <Text
            variant={"h5"}
            bold
            style={{
              ...(styles.subHeading as React.CSSProperties),
            }}
          >
            8. Privacy
          </Text>
          <div className="content">
            <Typography variant="body1">
              QuickTask places a paramount emphasis on user privacy, and any personal information
              volunteered on the QuickTask Website, App, or Services is governed by our
              comprehensive Privacy Policy.
            </Typography>

            <div style={{marginLeft: "5px"}}>
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  8.1 Commitment to Privacy
                </Typography>
                <Typography variant="body1">
                  QuickTask is unwavering in its commitment to maintaining the privacy and
                  confidentiality of user information. The platform employs robust security measures
                  and privacy protocols to ensure that user data is handled with the utmost care and
                  diligence.
                </Typography>
              </div>
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  8.2 Encouraging User Understanding
                </Typography>
                <Typography variant="body1">
                  Users are actively encouraged to review the Privacy Policy provided by QuickTask.
                  This essential document outlines the specifics of how user data is collected,
                  used, stored, and protected by the platform. It serves as a crucial resource for
                  users to understand the principles and practices governing the handling of their
                  personal information.
                </Typography>
              </div>{" "}
              <Typography variant="h6" style={{marginTop: "10px"}}>
                Additional Points:
              </Typography>
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  8.3 Transparent Data Handling Practices
                </Typography>
                <Typography variant="body1">
                  QuickTask's Privacy Policy is designed to be transparent and accessible, providing
                  users with clear insights into the platform's data handling practices.
                  Understanding these practices empowers users to make informed decisions about
                  their engagement with QuickTask.
                </Typography>
              </div>
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  8.4 Security Measures
                </Typography>
                <Typography variant="body1">
                  The commitment to user privacy extends to the implementation of stringent security
                  measures. QuickTask employs industry-standard practices to safeguard user data
                  against unauthorised access, ensuring a secure environment for all users.
                </Typography>
              </div>{" "}
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  8.5 Continuous Updates and Compliance
                </Typography>
                <Typography variant="body1">
                  QuickTask is dedicated to keeping its Privacy Policy updated to align with
                  evolving privacy standards and legal requirements. Users can rely on QuickTask to
                  adhere to best practices and maintain compliance with relevant privacy
                  regulations.
                </Typography>
              </div>
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  8.6 User Education
                </Typography>
                <Typography variant="body1">
                  In addition to reviewing the Privacy Policy, QuickTask engages in ongoing efforts
                  to educate users about the importance of privacy and data security. This proactive
                  approach contributes to a well-informed user community that values and prioritises
                  privacy in their interactions with the platform.
                </Typography>
              </div>
            </div>
          </div>
          {/*section 9*/}
          <div style={{height: "48px"}} />
          <Text
            variant={"h5"}
            bold
            style={{
              ...(styles.subHeading as React.CSSProperties),
            }}
          >
            9. Modifications to the Agreement{" "}
          </Text>

          <div className="content">
            <Typography variant="body1">
              QuickTask reserves the right to modify these Terms or the Policies at any time in its
              sole discretion without notifying the user.
            </Typography>
            <Typography variant="h6" style={{marginTop: "10px"}}>
              Key Provisions:
            </Typography>

            <div style={{marginLeft: "5px"}}>
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  9.1 Unilateral Modification Authority:
                </Typography>
                <Typography variant="body1">
                  QuickTask maintains the unilateral authority to modify Terms or Policies based on
                  its discretion. This flexibility allows QuickTask to adapt to changing
                  circumstances, improve services, or address emerging needs within the platform.
                </Typography>
              </div>
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  9.2 Notification Mechanisms:
                </Typography>
                <Typography variant="body1">
                  Users will be notified of any changes to the Terms or Policies through official
                  communication channels facilitated by the platform. These channels include
                  in-platform notifications, emails, or other designated communication methods
                  chosen by QuickTask.
                </Typography>
              </div>{" "}
              <Typography variant="h6" style={{marginTop: "10px"}}>
                Additional Points:
              </Typography>
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  9.3 User Awareness Responsibility:
                </Typography>
                <Typography variant="body1">
                  It is the user's responsibility to stay informed about any updates to the Terms
                  and Policies. Users are encouraged to regularly check for notifications, read
                  emails, or refer to designated communication channels to be aware of any
                  modifications made by QuickTask.
                </Typography>
              </div>{" "}
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  9.4 Ensuring Transparency:
                </Typography>
                <Typography variant="body1">
                  QuickTask is committed to ensuring transparency in communicating changes to its
                  users. Notifications aim to provide users with clear information about
                  modifications, enabling them to understand the evolving terms and policies
                  governing their engagement with the platform.
                </Typography>
              </div>
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  9.5 Acknowledging and Accepting Modifications:
                </Typography>
                <Typography variant="body1">
                  By using the QuickTask platform, users implicitly acknowledge and accept the
                  possibility of modifications to the Terms or Policies. Staying informed empowers
                  users to make well-informed decisions and align their actions with the current
                  guidelines set by QuickTask.
                </Typography>
              </div>
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  9.6 Proactive User Engagement:
                </Typography>
                <Typography variant="body1">
                  Proactive engagement with notifications and updates enhances the user's
                  understanding of the evolving landscape. QuickTask encourages users to actively
                  participate in staying informed, contributing to a collaborative and well-informed
                  community.
                </Typography>
              </div>
              <Typography variant="body1">
                By recognizing the dynamic nature of the QuickTask platform and staying engaged with
                notifications, users contribute to maintaining a transparent and adaptive
                environment.
              </Typography>
            </div>
          </div>
          {/*section 10*/}
          <div style={{height: "48px"}} />
          <Text
            variant={"h5"}
            bold
            style={{
              ...(styles.subHeading as React.CSSProperties),
            }}
          >
            10. Notices{" "}
          </Text>

          <div className="content">
            <Typography variant="body1">
              Except as stated otherwise, any notices must be given by registered ordinary post or
              by email, either to the QuickTask contact address as displayed on the QuickTask
              Platform or to the QuickTask Users' contact address as provided at registration.
            </Typography>
            <Typography variant="h6" style={{marginTop: "10px"}}>
              Key Provisions:
            </Typography>

            <div style={{marginLeft: "5px"}}>
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  10.1 Communication Channels:
                </Typography>
                <Typography variant="body1">
                  The designated communication channels for notices include registered ordinary
                  posts and email. Users are required to use these official channels for effective
                  communication with QuickTask.
                </Typography>
              </div>
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  10.2 Recipient Addresses:{" "}
                </Typography>
                <Typography variant="body1">
                  Notices should be directed to the QuickTask contact address as displayed on the
                  QuickTask Platform or the contact address provided by QuickTask Users during
                  registration. This ensures that communications reach the intended recipients
                  promptly. chosen by QuickTask.
                </Typography>
              </div>{" "}
              <Typography variant="h6" style={{marginTop: "10px"}}>
                Additional Points:
              </Typography>
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  10.3 Maintaining Effective Communication:{" "}
                </Typography>
                <Typography variant="body1">
                  Notices play a crucial role in maintaining effective communication between
                  QuickTask and its users. It serves as a means to convey important information,
                  updates, or changes related to the platform, policies, or user accounts.
                </Typography>
              </div>{" "}
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  10.4 User Responsibility:{" "}
                </Typography>
                <Typography variant="body1">
                  Users bear the responsibility of ensuring their contact information is accurate
                  and up-to-date. Keeping contact details current is essential to receive important
                  notifications, announcements, and updates from QuickTask in a timely manner.
                </Typography>
              </div>
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  10.5 Importance of Timely Updates:{" "}
                </Typography>
                <Typography variant="body1">
                  Timely updates through notices contribute to a seamless user experience. Users
                  benefit from being informed promptly about any changes or developments that may
                  impact their engagement with the QuickTask platform.
                </Typography>
              </div>
              <Typography variant="body1">
                By adhering to these notice provisions and recognizing their significance, users
                contribute to the overall efficiency of communication within the QuickTask
                community. Ensuring accurate contact information helps maintain a transparent and
                well-informed user environment.
              </Typography>
            </div>
          </div>
          {/*section 11*/}
          <div style={{height: "48px"}} />
          <Text
            variant={"h5"}
            bold
            style={{
              ...(styles.subHeading as React.CSSProperties),
            }}
          >
            11. Equitable Relief
          </Text>

          <div className="content">
            <Typography variant="body1">
              The Tasker Poster and Tasker acknowledge that QuickTask may suffer irreparable harm in
              the event of a breach of this Agreement. Therefore, in addition to monetary relief,
              the Company is entitled to equitable relief, including temporary, preliminary, and/or
              permanent injunctive relief to restrain any threatened, actual, continuing, or further
              breach without showing or proving actual damages.
            </Typography>
            <Typography variant="h6" style={{marginTop: "10px"}}>
              Key Provisions:
            </Typography>

            <div style={{marginLeft: "5px"}}>
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  11.1 Irreparable Harm:{" "}
                </Typography>
                <Typography variant="body1">
                  QuickTask and the involved parties recognize that certain breaches of the
                  Agreement may result in irreparable harm to the platform. Irreparable harm refers
                  to damage that cannot be adequately compensated through monetary means alone.
                </Typography>
              </div>
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  11.2 Entitlement to Equitable Relief:
                </Typography>
                <Typography variant="body1">
                  In acknowledgement of the potentially irreparable harm, QuickTask is granted the
                  right to seek equitable relief in the form of injunctions. This includes temporary
                  measures to prevent immediate harm, preliminary relief during legal proceedings,
                  and permanent injunctions to restrain ongoing or future breaches.
                </Typography>
              </div>{" "}
              <Typography variant="h6" style={{marginTop: "10px"}}>
                Additional Points:
              </Typography>
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  11.3 Upholding Agreement Terms:{" "}
                </Typography>
                <Typography variant="body1">
                  QuickTask places significant emphasis on the importance of all parties upholding
                  the terms of this Agreement. Adherence to the agreed-upon terms is crucial for
                  maintaining a fair and equitable environment within the QuickTask community.
                </Typography>
              </div>{" "}
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  11.4 Preventing Potential Harm:{" "}
                </Typography>
                <Typography variant="body1">
                  Equitable relief is pursued not only as a remedy for harm suffered but as a
                  proactive measure to prevent potential harm caused by a breach of this Agreement.
                  QuickTask aims to address issues swiftly and effectively to maintain the integrity
                  of the platform.
                </Typography>
              </div>
              <Typography variant="body1">
                By acknowledging and agreeing to these provisions, users and QuickTask mutually
                recognize the necessity of equitable relief in certain situations to protect the
                platform, its users, and the overall user experience.
              </Typography>
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  Additional Points:
                </Typography>
                <Typography variant="body1">
                  QuickTask emphasises the importance of upholding the terms of this Agreement to
                  maintain a fair and equitable environment.
                </Typography>
                <Typography variant="body1">
                  Equitable relief is pursued when necessary to prevent potential harm caused by a
                  breach of this Agreement.
                </Typography>
              </div>
            </div>
          </div>
          {/*section 12*/}
          <div style={{height: "48px"}} />
          <Text
            variant={"h5"}
            bold
            style={{
              ...(styles.subHeading as React.CSSProperties),
            }}
          >
            12. Dispute Resolution Procedures
          </Text>

          <div className="content">
            <Typography variant="body1">
              QuickTask is dedicated to fostering a collaborative and constructive user community,
              encouraging users to resolve disputes directly with one another, including claims for
              returns or refunds. However, QuickTask reserves the right to involve itself at its own
              discretion in a dispute, particularly when a clear fault is identified on either the
              tasker or the taskmaster's side.
            </Typography>
            <Typography variant="h6" style={{marginTop: "10px"}}>
              Key Provisions:
            </Typography>

            <div style={{marginLeft: "5px"}}>
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  12.1 User Attempted Resolution:
                </Typography>
                <Typography variant="body1">
                  Users are initially encouraged to engage in direct communication to resolve
                  disputes amicably. Open dialogue contributes to a positive and collaborative user
                  community.
                </Typography>
              </div>
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  12.2 QuickTask Mediation:{" "}
                </Typography>
                <Typography variant="body1">
                  In certain cases, where a dispute remains unresolved or escalates, QuickTask may,
                  at its own discretion, act as a mediator. This involvement aims to ensure fair and
                  impartial dispute resolution.
                </Typography>
              </div>{" "}
              <Typography variant="h6" style={{marginTop: "10px"}}>
                Additional Points:
              </Typography>
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  12.3 Fair Mediation:{" "}
                </Typography>
                <Typography variant="body1">
                  QuickTask's mediation role is designed to be fair and unbiased, facilitating a
                  resolution that considers the interests of all parties involved.
                </Typography>
              </div>{" "}
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  12.4 Discretionary Involvement:{" "}
                </Typography>
                <Typography variant="body1">
                  QuickTask retains the discretion to become involved in disputes based on the
                  severity and nature of the issue. This discretionary involvement is exercised to
                  maintain the integrity of the platform.
                </Typography>
              </div>
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  12.5 User Cooperation:
                </Typography>
                <Typography variant="body1">
                  Users are expected to cooperate with QuickTask's mediation efforts, providing
                  relevant information and adhering to the resolutions proposed during the dispute
                  resolution process.
                </Typography>
              </div>
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  12.6 User Confidence:
                </Typography>
                <Typography variant="body1">
                  QuickTask's involvement in dispute resolution is aimed at instilling confidence in
                  the fairness and reliability of the platform. Users can trust that QuickTask will
                  actively address disputes when necessary.
                </Typography>
              </div>
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  12.7 Limitation of Responsibility:{" "}
                </Typography>
                <Typography variant="body1">
                  In cases where either party is dissatisfied with the outcome of a dispute
                  resolution process involving QuickTask, it is explicitly acknowledged that
                  QuickTask will not be held responsible for the dissatisfaction. Users agree that
                  QuickTask's role in dispute resolution is conducted in good faith, and any
                  decision made is final.
                </Typography>
              </div>
              <Typography variant="body1">
                By participating in the QuickTask community, users acknowledge and agree to these
                dispute resolution procedures, understanding that QuickTask may, under certain
                circumstances, intervene to ensure a just and satisfactory resolution for all
                parties involved, with the understanding that QuickTask will not be held responsible
                for any dissatisfaction with the resolution.
              </Typography>
            </div>
          </div>
          {/*section 13*/}
          <div style={{height: "48px"}} />
          <Text
            variant={"h5"}
            bold
            style={{
              ...(styles.subHeading as React.CSSProperties),
            }}
          >
            13. User Conduct Violations
          </Text>

          <div className="content">
            <Typography variant="body1">
              QuickTask users are expected to adhere to a code of conduct that upholds the integrity
              and fair practices of the platform. Violations of user conduct include:
            </Typography>
            <Typography variant="h6" style={{marginTop: "10px"}}>
              Key Provisions:
            </Typography>

            <div style={{marginLeft: "5px"}}>
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  13.1 Prohibition of Direct Communication
                </Typography>
                <Typography variant="body1">
                  Users must refrain from direct communication, including but not limited to
                  contacting taskmasters or taskers outside of the QuickTask platform. This
                  prohibition ensures that the platform serves as a secure intermediary for
                  communication, promoting transparency and accountability.
                </Typography>
              </div>
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  13.2 Restriction on Off-Platform Payments
                </Typography>
                <Typography variant="body1">
                  Accepting payments outside of the QuickTask platform is strictly prohibited. Users
                  are required to conduct all financial transactions through the platform to
                  maintain a secure and reliable payment process, fostering a trustworthy financial
                  exchange environment.
                </Typography>
              </div>{" "}
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  13.3 Condemnation of Fraudulent Activities:
                </Typography>
                <Typography variant="body1">
                  Engaging in any form of fraudulent activity, such as providing false information
                  or identification, is a severe violation of QuickTask's user conduct policy.
                  QuickTask places a high priority on trust and authenticity within its community,
                  and any breach of this policy undermines the foundation of the platform.
                </Typography>
              </div>{" "}
              <Typography variant="h6" style={{marginTop: "10px"}}>
                Additional Points:
              </Typography>
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  13.4 Upholding Integrity
                </Typography>
                <Typography variant="body1">
                  The outlined prohibitions aim to preserve the integrity of the QuickTask platform,
                  fostering a trustworthy and collaborative environment for all users. Upholding
                  integrity is fundamental to creating a positive and ethical community where users
                  can rely on the platform for fair and secure interactions.
                </Typography>
              </div>
              <div>
                <Typography variant="h6" style={{marginTop: "10px"}}>
                  13.5 Penalties for Violations{" "}
                </Typography>
                <Typography variant="body1">
                  Violations of user conduct may result in penalties imposed by QuickTask. These
                  penalties can range from warnings to temporary account suspension or, in severe
                  cases, permanent termination. QuickTask is committed to enforcing these measures
                  diligently to ensure a fair, secure, and positive user experience for all
                  participants on the platform.
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
